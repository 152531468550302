<template>
  <div class="page">
    <div class="w-90 h-full">
      <Banner action="getHomeSlide" getter="homeSlide"/>

      <div class="home-media-list relative z-10 space-y-12 mt-10">
        <div class="container">
          <!-- Async section -->
          <!--        <Section class="latest-history" title="Сүүлд үзсэн" v-if="$store.state.user != null" :ep-number="true"-->
          <!--                 action="getLatestHistory" getter="latestHistoryEpisodes"/>-->

          <Section title="Шинэ контент" action="getLatestEpisodes" getter="latestEpisodes" moreLink="/episode/new"/>
          <Section title="Эрэлттэй контент" action="getLatestEpisodes" getter="latestEpisodes" moreLink="/episode/new"/>
          <!--        <Section title="Энэ улиралд" :num="false" action="getSeasonMedia" getter="seasonMedias"/>-->
        </div>

        <div class="section-block">
          <div class="container">
            <div class="grid gap-12 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-2">
              <div class="block-content">
                <div class="block-header">
                  <router-link to="/news">
                    <span>Мэдээ</span>
                    <i class="isax isax-arrow-right-3"></i>
                  </router-link>
                </div>

                <div class="block-content-body">
                  <article
                      class="c-post cover-post">
                    <div class="post-media overflow-hidden">
                      <div class="featured-image bg-gray-25 dark:bg-gray-800 ratio ratio-4x3"
                           style="background-image: url('/img/posts/img-01.jpg')"></div>
                    </div>
                    <div class="position-cover bg-gradient-to-t from-black to-transparent opacity-90"></div>
                    <div
                        class="post-header panel vstack justify-start items-start flex-column-reverse gap-1 p-2 position-cover text-white">
                      <div class="post-meta panel hstack justify-between fs-7 text-white text-opacity-60 mt-1">
                        <div class="meta">
                          <div class="hstack gap-2">
                            <div>
                              <div class="post-author hstack gap-1">
                                <a href="page-author.html" data-uc-tooltip="David Peterson"><img
                                    src="/img/avatars/01.png" alt="David Peterson"
                                    class="w-24px h-24px rounded-circle"></a>
                                <a href="page-author.html" class="author-txt">David
                                  Peterson</a>
                              </div>
                            </div>

                            <div>
                              <a href="#post_comment" class="post-comments text-none hstack gap-narrow">
                                <i class="icon-narrow unicon-chat"></i>
                                <span>2</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="actions">
                          <div class="hstack gap-1"></div>
                        </div>
                      </div>
                      <h3 class="post-title h6 lg:h5 m-0 m-0 max-w-600px text-white text-truncate-2">
                        <a class="text-none text-white" href="blog-details.html">The Rise of AI-Powered Personal
                          Assistants: How They Manage</a>
                      </h3>
                      <div
                          class="post-date hstack gap-narrow fs-7 text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                        <span>1min</span>
                      </div>
                    </div>
                    <a href="blog-details.html" class="position-cover"></a>
                  </article>

                  <article class="c-post">
                    <div class="uc-first-column">
                      <div class="post-header panel vstack justify-between gap-1">
                        <h3 class="post-title h6 m-0 text-truncate-2">
                          <router-link class="text-none hover:text-primary duration-150" to="/news">Tech
                            Innovations Reshaping the Retail Landscape: AI Payments
                          </router-link>
                        </h3>
                        <div class="post-date">
                          <span>55min</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-auto">
                      <div class="post-media">
                        <div class="featured-image" style="background-image: url('/img/posts/img-02.jpg')">
                        </div>
                        <a href="/news" class="position-cover"></a>
                      </div>
                    </div>
                  </article>

                  <article class="c-post">
                    <div class="uc-first-column">
                      <div class="post-header panel vstack justify-between gap-1">
                        <h3 class="post-title h6 m-0 text-truncate-2">
                          <router-link class="text-none hover:text-primary duration-150" to="/news">Tech
                            Innovations Reshaping the Retail Landscape: AI Payments
                          </router-link>
                        </h3>
                        <div class="post-date">
                          <span>55min</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-auto">
                      <div class="post-media">
                        <div class="featured-image" style="background-image: url('/img/posts/img-03.jpg')">
                        </div>
                        <a href="/news" class="position-cover"></a>
                      </div>
                    </div>
                  </article>

                  <article class="c-post">
                    <div class="uc-first-column">
                      <div class="post-header panel vstack justify-between gap-1">
                        <h3 class="post-title h6 m-0 text-truncate-2">
                          <router-link class="text-none hover:text-primary duration-150" to="/news">Tech
                            Innovations Reshaping the Retail Landscape: AI Payments
                          </router-link>
                        </h3>
                        <div class="post-date">
                          <span>55min</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-auto">
                      <div class="post-media">
                        <div class="featured-image" style="background-image: url('/img/posts/img-04.jpg')">
                        </div>
                        <a href="/news" class="position-cover"></a>
                      </div>
                    </div>
                  </article>
                </div>
              </div>

              <div class="block-content">
                <div class="block-header">
                  <router-link to="/news">
                    <span>Товхимол</span>
                    <i class="isax isax-arrow-right-3"></i>
                  </router-link>
                </div>

                <div class="block-content-body">
                  <article
                      class="c-post cover-post">
                    <div class="post-media overflow-hidden">
                      <div class="featured-image bg-gray-25 dark:bg-gray-800 ratio ratio-4x3"
                           style="background-image: url('/img/posts/img-03.jpg')"></div>
                    </div>
                    <div class="position-cover bg-gradient-to-t from-black to-transparent opacity-90"></div>
                    <div
                        class="post-header panel vstack justify-start items-start flex-column-reverse gap-1 p-2 position-cover text-white">
                      <div class="post-meta panel hstack justify-between fs-7 text-white text-opacity-60 mt-1">
                        <div class="meta">
                          <div class="hstack gap-2">
                            <div>
                              <div class="post-author hstack gap-1">
                                <a href="page-author.html" data-uc-tooltip="David Peterson"><img
                                    src="/img/avatars/01.png" alt="David Peterson"
                                    class="w-24px h-24px rounded-circle"></a>
                                <a href="page-author.html" class="author-txt">David
                                  Peterson</a>
                              </div>
                            </div>

                            <div>
                              <a href="#post_comment" class="post-comments text-none hstack gap-narrow">
                                <i class="icon-narrow unicon-chat"></i>
                                <span>2</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="actions">
                          <div class="hstack gap-1"></div>
                        </div>
                      </div>
                      <h3 class="post-title h6 lg:h5 m-0 m-0 max-w-600px text-white text-truncate-2">
                        <a class="text-none text-white" href="blog-details.html">The Rise of AI-Powered Personal
                          Assistants: How They Manage</a>
                      </h3>
                      <div
                          class="post-date hstack gap-narrow fs-7 text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                        <span>1min</span>
                      </div>
                    </div>
                    <a href="blog-details.html" class="position-cover"></a>
                  </article>

                  <article class="c-post">
                    <div class="uc-first-column">
                      <div class="post-header panel vstack justify-between gap-1">
                        <h3 class="post-title h6 m-0 text-truncate-2">
                          <router-link class="text-none hover:text-primary duration-150" to="/news">Tech
                            Innovations Reshaping the Retail Landscape: AI Payments
                          </router-link>
                        </h3>
                        <div class="post-date">
                          <span>55min</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-auto">
                      <div class="post-media">
                        <div class="featured-image" style="background-image: url('/img/posts/img-02.jpg')">
                        </div>
                        <a href="/news" class="position-cover"></a>
                      </div>
                    </div>
                  </article>

                  <article class="c-post">
                    <div class="uc-first-column">
                      <div class="post-header panel vstack justify-between gap-1">
                        <h3 class="post-title h6 m-0 text-truncate-2">
                          <router-link class="text-none hover:text-primary duration-150" to="/news">Tech
                            Innovations Reshaping the Retail Landscape: AI Payments
                          </router-link>
                        </h3>
                        <div class="post-date">
                          <span>55min</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-auto">
                      <div class="post-media">
                        <div class="featured-image" style="background-image: url('/img/posts/img-03.jpg')">
                        </div>
                        <a href="/news" class="position-cover"></a>
                      </div>
                    </div>
                  </article>

                  <article class="c-post">
                    <div class="uc-first-column">
                      <div class="post-header panel vstack justify-between gap-1">
                        <h3 class="post-title h6 m-0 text-truncate-2">
                          <router-link class="text-none hover:text-primary duration-150" to="/news">Tech
                            Innovations Reshaping the Retail Landscape: AI Payments
                          </router-link>
                        </h3>
                        <div class="post-date">
                          <span>55min</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-auto">
                      <div class="post-media">
                        <div class="featured-image" style="background-image: url('/img/posts/img-04.jpg')">
                        </div>
                        <a href="/news" class="position-cover"></a>
                      </div>
                    </div>
                  </article>
                </div>
              </div>

              <div class="block-content">
                <div class="block-header">
                  <router-link to="/news">
                    <span>Подкаст</span>
                    <i class="isax isax-arrow-right-3"></i>
                  </router-link>
                </div>

                <div class="block-content-body">
                  <article
                      class="c-post cover-post">
                    <div class="post-media overflow-hidden">
                      <div class="featured-image bg-gray-25 dark:bg-gray-800 ratio ratio-4x3"
                           style="background-image: url('/img/posts/img-01.jpg')"></div>
                    </div>
                    <div class="position-cover bg-gradient-to-t from-black to-transparent opacity-90"></div>
                    <div
                        class="post-header panel vstack justify-start items-start flex-column-reverse gap-1 p-2 position-cover text-white">
                      <div class="post-meta panel hstack justify-between fs-7 text-white text-opacity-60 mt-1">
                        <div class="meta">
                          <div class="hstack gap-2">
                            <div>
                              <div class="post-author hstack gap-1">
                                <a href="page-author.html" data-uc-tooltip="David Peterson"><img
                                    src="/img/avatars/01.png" alt="David Peterson"
                                    class="w-24px h-24px rounded-circle"></a>
                                <a href="page-author.html" class="author-txt">David
                                  Peterson</a>
                              </div>
                            </div>

                            <div>
                              <a href="#post_comment" class="post-comments text-none hstack gap-narrow">
                                <i class="icon-narrow unicon-chat"></i>
                                <span>2</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="actions">
                          <div class="hstack gap-1"></div>
                        </div>
                      </div>
                      <h3 class="post-title h6 lg:h5 m-0 m-0 max-w-600px text-white text-truncate-2">
                        <a class="text-none text-white" href="blog-details.html">The Rise of AI-Powered Personal
                          Assistants: How They Manage</a>
                      </h3>
                      <div
                          class="post-date hstack gap-narrow fs-7 text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                        <span>1min</span>
                      </div>
                    </div>
                    <a href="blog-details.html" class="position-cover"></a>
                  </article>

                  <article class="c-post">
                    <div class="uc-first-column">
                      <div class="post-header panel vstack justify-between gap-1">
                        <h3 class="post-title h6 m-0 text-truncate-2">
                          <router-link class="text-none hover:text-primary duration-150" to="/news">Tech
                            Innovations Reshaping the Retail Landscape: AI Payments
                          </router-link>
                        </h3>
                        <div class="post-date">
                          <span>55min</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-auto">
                      <div class="post-media">
                        <div class="featured-image" style="background-image: url('/img/posts/img-02.jpg')">
                        </div>
                        <a href="/news" class="position-cover"></a>
                      </div>
                    </div>
                  </article>

                  <article class="c-post">
                    <div class="uc-first-column">
                      <div class="post-header panel vstack justify-between gap-1">
                        <h3 class="post-title h6 m-0 text-truncate-2">
                          <router-link class="text-none hover:text-primary duration-150" to="/news">Tech
                            Innovations Reshaping the Retail Landscape: AI Payments
                          </router-link>
                        </h3>
                        <div class="post-date">
                          <span>55min</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-auto">
                      <div class="post-media">
                        <div class="featured-image" style="background-image: url('/img/posts/img-03.jpg')">
                        </div>
                        <a href="/news" class="position-cover"></a>
                      </div>
                    </div>
                  </article>

                  <article class="c-post">
                    <div class="uc-first-column">
                      <div class="post-header panel vstack justify-between gap-1">
                        <h3 class="post-title h6 m-0 text-truncate-2">
                          <router-link class="text-none hover:text-primary duration-150" to="/news">Tech
                            Innovations Reshaping the Retail Landscape: AI Payments
                          </router-link>
                        </h3>
                        <div class="post-date">
                          <span>55min</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-auto">
                      <div class="post-media">
                        <div class="featured-image" style="background-image: url('/img/posts/img-04.jpg')">
                        </div>
                        <a href="/news" class="position-cover"></a>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Section block -->
        <div id="live">
          <div class="section-block bg-dark">
          <div class="container">
            <div class="section-title">
              <span class="live-dot"></span>
              <h3>Live Now</h3>
            </div>
            <div class="section-body">
              <div class="grid grid-flow-row-dense grid-cols-12">
                <div class="col-span-9">
                  <div class="live-video">
                    <article class="c-post cover-post">
                      <div class="post-media">
                        <div class="featured-video">
                          <video-background
                              src="/videos/vid-01.webm"
                              style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;"
                          />
                        </div>
                      </div>
                      <div class="position-cover bg-gradient-to-t from-black to-transparent z-1 opacity-80"></div>
                      <div
                          class="post-header panel vstack justify-start items-start gap-1 p-2 position-cover text-white">
                        <h3 class="post-title">
                          <a class="text-none" href="blog-details.html">AI-Powered Financial Planning: How Algorithms
                            Revolutionizing</a>
                        </h3>
                        <div>
                          <div
                              class="post-meta panel hstack justify-between fs-7 fw-medium text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                            <div class="meta">
                              <div class="hstack gap-2">
                                <div>
                                  <div class="post-author hstack gap-1">
                                    <a href="page-author.html" data-uc-tooltip="Sarah Eddrissi"><img
                                        src="/img/avatars/03.png" alt="Sarah Eddrissi"
                                        class="w-24px h-24px rounded-circle"></a>
                                    <a href="page-author.html" class="text-white dark:text-white text-none fw-bold">Sarah
                                      Eddrissi</a>
                                  </div>
                                </div>
                                <div>
                                  <div class="post-date hstack gap-narrow">
                                    <span>2mo ago</span>
                                  </div>
                                </div>
                                <div>
                                  <a href="#post_comment" class="post-comments text-none hstack gap-narrow">
                                    <i class="icon-narrow unicon-chat"></i>
                                    <span>2</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="actions">
                              <div class="hstack gap-1">
                                <ul>
                                  <li></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
                <div class="col-span-3">
                  <div class="live-program">
                    <h3>Өнөөдрийн хөтөлбөр</h3>
                    <ul class="program-list">
                      <li>
                        <div class="info">
                          <strong>1</strong>
                          <small>10:00-12:00</small>
                        </div>
                        <p class="text-white">Balancing Work and Wellness:
                          Tech Solutions for Healthy</p>
                      </li>

                      <li class="active">
                        <div class="info">
                          <strong>2</strong>
                          <small>12:00-13:30</small>
                        </div>
                        <p class="text-white">AI-Powered Financial Planning: How Algorithms Revolutionizing</p>
                        <div class=""></div>
                      </li>


                      <li>
                        <div class="info">
                          <strong>3</strong>
                          <small>10:00-12:00</small>
                        </div>
                        <p class="text-white">The Rise of AI-Powered Personal Assistants: How They Manage</p>
                      </li>

                      <li>
                        <div class="info">
                          <strong>4</strong>
                          <small>10:00-12:00</small>
                        </div>
                        <p class="text-white">Balancing Work and Wellness:
                          Tech Solutions for Healthy</p>
                      </li>

                      <li>
                        <div class="info">
                          <strong>5</strong>
                          <small>10:00-12:00</small>
                        </div>
                        <p class="text-white">Tech Innovations Reshaping the Retail Landscape: AI Payments </p>
                      </li>

                      <li>
                        <div class="info">
                          <strong>6</strong>
                          <small>10:00-12:00</small>
                        </div>
                        <p class="text-white">Balancing Work and Wellness:
                          Tech Solutions for Healthy</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

        <div class="subscribe">
          <div class="container">
            <h2 class="subscribe__title">Let's keep in touch</h2>
            <p class="subscribe__copy">Subscribe to keep up with fresh news and exciting updates. We promise not to spam
              you!</p>
            <div class="form">
              <input type="email" class="form__email" placeholder="Имэйл хаягаа оруулна уу"/>
              <button class="form__button">
                <span>Илгээх</span>
                <i class="uil-envelope-send"></i>
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Section from "@components/Section.vue";
import Banner from "@components/BannerMulti.vue";
import useDevice from "@hooks/useDevice";
import VideoBackground from "vue-responsive-video-background-player";

export default {
  components: {
    VideoBackground,
    Section,
    Banner,
  },
  setup() {
    const {isDesktop} = useDevice();
    return {isDesktop};
  },

  created() {
  },

  mounted() {
  }
};
</script>